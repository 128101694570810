import React from 'react';
import CountrySelect from '../../components/Miscellaneous/CountrySelect';
import CustomFormErrorMessage from '../../ui/CustomFormErrorMessage';
import { useTranslation } from 'react-i18next';

export default function FormattedCountrySelect({
  fromSSO = false,
  disabled = false,
}) {
  const { t } = useTranslation();
  return (
    <div className="form-group col-12 col-md-6">
      <label htmlFor="country">{t('forms.country')}</label>
      <CountrySelect fromSSO={fromSSO} disabled={disabled} />
      <CustomFormErrorMessage name="country" />
    </div>
  );
}
