import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useUser } from '@util/providers/AuthProvider';

export default function EmailFound({ isOpen, toggle, secondaryAction }) {
  const { t } = useTranslation();
  const { signIn } = useUser();

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader
      //   toggle={toggle} charCode=""
      >
        {t('account.accountFoundTitle')}
      </ModalHeader>
      <ModalBody>{t('account.accountFoundMessage')}</ModalBody>
      <ModalFooter className="pb-5">
        <button
          className="btn btn-primary"
          id="EmailFoundLogIn"
          onClick={() => signIn()}
        >
          {t('account.logIn')}
        </button>
        <button
          className="btn btn-outline-secondary"
          id="EmailFoundCreateAccount"
          onClick={() => {
            toggle();
            secondaryAction();
          }}
        >
          {t('account.createAccount')}
        </button>
      </ModalFooter>
    </Modal>
  );
}
