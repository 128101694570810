import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { boardmakerToSSOLanguage } from '@util/functions';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

import imgMeter0 from '@images/passwordMeter/meter-0.svg';
import imgMeter00 from '@images/passwordMeter/meter-00.svg';
import imgMeter1 from '@images/passwordMeter/meter-1.svg';
import imgMeter2 from '@images/passwordMeter/meter-2.svg';
import imgMeter3 from '@images/passwordMeter/meter-3.svg';
import imgMeter4 from '@images/passwordMeter/meter-4.svg';
import i18n from '../../i18n/i18n';

var CancelToken = axios.CancelToken;
var cancel;

export default function PasswordMeter({
  password,
  setPasswordScore: setFormPasswordScore,
}) {
  const { t } = useTranslation();
  const [passwordScore, setPasswordScore] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getPasswordScore() {
      setLoading(true);

      if (cancel !== undefined) cancel();

      const params = {
        password,
      };

      const headers = {
        'Accept-Language': boardmakerToSSOLanguage(i18n.language),
      };

      const result = await axios({
        method: 'post',
        url: `${process.env.SSO_URL}/api/account/password/score`,
        headers: headers,
        data: params,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
        .then(function(res) {
          setPasswordScore({ data: res.data });
          setFormPasswordScore(res.data.score);
          setLoading(false);
        })
        .catch(function(thrown) {
          if (axios.isCancel(thrown)) {
            //console.log('Request canceled', thrown.message);
          } else {
            // handle error
          }
        });

      return result;
    }

    //* validate password
    if (password.length >= 8) {
      getPasswordScore();
    } else {
      if (cancel !== undefined) cancel();
      setFormPasswordScore(0);
    }
    // eslint-disable-next-line
  }, [password]);

  if (!password) {
    return (
      <Container>
        <Meter>{t('validation.password.minLength')}</Meter>
      </Container>
    );
  }
  if (password.length < 8) {
    return (
      <Container id="MeterTooShort">
        <Meter>
          <div className="meter-0" />
          <span className="title">{t('validation.password.tooShort')}</span>
        </Meter>
        <UncontrolledPopover
          trigger="hover"
          placement="bottom"
          target="MeterTooShort"
          hideArrow
        >
          <PopoverBody>{t('validation.password.minLength')}</PopoverBody>
        </UncontrolledPopover>
      </Container>
    );
  }

  if (loading)
    return (
      <Container>
        <Meter>
          <div className="meter-00" />
          <span className="title">{t('validation.password.checking')}</span>
        </Meter>
      </Container>
    );

  if (!passwordScore.data) return null;

  return (
    <Container id="Meter">
      <Meter>
        <div className={`meter-${passwordScore.data.score}`} />
        <span className="title">{passwordScore.data.tipTitle}</span>
      </Meter>
      {passwordScore.data.feedback &&
        passwordScore.data.feedback.suggestions &&
        passwordScore.data.feedback.suggestions.length > 0 && (
          <UncontrolledPopover
            trigger="hover"
            placement="bottom"
            target="Meter"
            hideArrow
          >
            <PopoverBody>
              <span>
                <PasswordTipMessage data={passwordScore.data} />
              </span>
            </PopoverBody>
          </UncontrolledPopover>
        )}
    </Container>
  );
}

function PasswordTipMessage({ data }) {
  return (
    <PTMContainer>
      {data.feedback.warning && <TipWarning warning={data.feedback.warning} />}
      {data.feedback.suggestions && data.feedback.suggestions.length > 0 && (
        <TipSuggestions suggestions={data.feedback.suggestions} />
      )}
    </PTMContainer>
  );
}
const PTMContainer = styled.div`
  font-size: 0.75rem;
`;

function TipWarning({ warning }) {
  return (
    <div>
      <b>{warning}</b>
    </div>
  );
}

function TipSuggestions({ suggestions }) {
  return (
    <SuggestionList>
      {suggestions.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </SuggestionList>
  );
}
const SuggestionList = styled.ul`
  margin: 0;
  padding-left: 1rem;
  li {
  }
`;

const Container = styled.div`
  font-size: 0.75em !important;
  margin-bottom: 0;
`;
const Meter = styled.div`
  margin-top: 5px;
  height: 18px;
  position: relative;
  cursor: pointer;
  color: #0e103e;

  .meter-0,
  .meter-1,
  .meter-2,
  .meter-3,
  .meter-4,
  .meter-00 {
    width: 62px;
    height: 3px;
    background-size: 62px 3px;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
    top: 7px;
    left: 0;
  }

  .title {
    position: absolute;
    top: 0;
    left: 70px;
  }
  .meter-00 {
    background: url(${imgMeter00});
  }
  .meter-0 {
    background: url(${imgMeter0});
  }
  .meter-1 {
    background: url(${imgMeter1});
  }
  .meter-2 {
    background: url(${imgMeter2});
  }
  .meter-3 {
    background: url(${imgMeter3});
  }
  .meter-4 {
    background: url(${imgMeter4});
  }
`;
