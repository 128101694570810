import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { boardmakerToSSOLanguage } from '@util/functions';
import { Field, FormikConsumer } from 'formik';
import { useTranslation } from 'react-i18next';

import { countryList } from '@util/addressFunctions';
import i18n from '../../i18n/i18n';

export default function CountrySelect({ disabled, fromSSO }) {
  const { t } = useTranslation();
  const [countries, setCountries] = useState();

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const headers = {
      'Accept-Language': boardmakerToSSOLanguage(i18n.language),
    };

    const loadData = () => {
      try {
        axios
          .get(`${process.env.SSO_URL}/api/account/countries`, {
            cancelToken: source.token,
            headers: headers,
          })
          .then(data => {
            setCountries(data.data);
          });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('cancelled', error.message);
        } else {
          // throw error;
          console.error(error.message);
        }
      }
    };

    fromSSO ? loadData() : setCountries(countryList());
    return () => {
      source.cancel();
    };
  }, [fromSSO, i18n.language]);

  if (countries) {
    return (
      <FormikConsumer>
        {({ values, setFieldValue }) => {
          return (
            <Field
              component="select"
              name="country"
              className="custom-select"
              id="countryField"
              disabled={disabled}
              onChange={e => {
                //* reset state dropdown
                values.hasOwnProperty('stateProvinceRegion') &&
                  setFieldValue('stateProvinceRegion', '');
                setFieldValue('country', e.target.value);
              }}
            >
              <option value="">{t('forms.selectYourCountry')}</option>
              {fromSSO
                ? countries.map((item, index) => (
                    <option
                      id={item.countryId}
                      key={item.countryId}
                      value={item.salesforceCountryCode}
                    >
                      {item.localizedName}
                    </option>
                  ))
                : Object.keys(countries).map((key, index) => {
                    return (
                      <option key={key} value={key}>
                        {countries[key]}
                      </option>
                    );
                  })}
            </Field>
          );
        }}
      </FormikConsumer>
    );
  }

  return null;
}
