import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function WhatIsTDAccount({
  isOpen,
  toggle,
  primaryAction,
  secondaryAction,
}) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} charCode="">
        {t('tdAccount.title')}
      </ModalHeader>
      <ModalBody>
        <h5>{t('tdAccount.header')}</h5>
        <p>{t('tdAccount.body')}</p>
      </ModalBody>
      <ModalFooter className="pb-5">
        <button
          className="btn btn-primary"
          id="WhatIsTDAccountBack"
          onClick={toggle}
        >
          {t('action.back')}
        </button>
      </ModalFooter>
    </Modal>
  );
}
