import React from 'react';
import { useTranslation } from 'react-i18next';
import { OptionalText } from './Optional';
import { Field } from 'formik';
import CustomFormErrorMessage from '@ui/CustomFormErrorMessage';

export default function FormGroup({
  name,
  submitTo,
  optional,
  formatText,
  select,
  defaultOption,
  options,
  hideLabel,
  fieldProps,
  className,
  children,
  helpText,
  overlay,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={className || 'form-group col-12 col-md-6 position-relative'}
    >
      {!hideLabel && (
        <label htmlFor={name}>
          {t(`forms.${name}`)}
          <span> </span>
          {formatText && <OptionalText text={formatText} />}
          {optional && <OptionalText text={t('forms.optional')} />}
        </label>
      )}

      {!children ? (
        !select ? (
          <>
            <Field
              name={submitTo ? submitTo : name}
              id={name}
              className="form-control"
              {...fieldProps}
            />
            {overlay}
            <CustomFormErrorMessage name={name} />
          </>
        ) : (
          <>
            <Field
              name={submitTo ? submitTo : name}
              id={name + 'Field'}
              component="select"
              className="form-control custom-select"
              {...fieldProps}
            >
              {options && (
                <>
                  {defaultOption && <option value="">{defaultOption}</option>}
                  {options.map(o => (
                    <option id={o} key={o} value={o}>
                      {o}
                    </option>
                  ))}
                </>
              )}
            </Field>
            <CustomFormErrorMessage name={name} />
          </>
        )
      ) : (
        <>
          {children}
          <CustomFormErrorMessage name={name} noTouch />
        </>
      )}

      {//* show help text
      helpText && <small className="text-muted form-text">{helpText}</small>}
    </div>
  );
}
