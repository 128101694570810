import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePlan } from '@util/providers/Plan';
import { useUser } from '@util/providers/AuthProvider';
import useSubscription from '@util/hooks/useSubscription';

import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import AccountModule from '../modules/Account';
import WhatIsTDAccount from '@components/Account/Modal/WhatIsTDAccount';

import Go from '@util/CustomRedirect';

const Account = ({ location }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Rules>
      <SEO title={t('page:createAccount')} />
      <div className="row mb-4">
        <div className="col-12 col-sm-6 col-xl-4">
          <PageHeader>{t('page:createAccount')}</PageHeader>
        </div>
        <div className="col-12 col-sm-6 col-xl-4 pt-2 text-sm-right">
          {t('tdAccount.haveA')} {/* eslint-disable-next-line */}
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
          >
            {t('tdAccount.tobiiDynavoxAccount')}
          </a>
        </div>
      </div>

      <AccountModule location={location} />
      <WhatIsTDAccount
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
      />
    </Rules>
  );
};

export default Account;

function Rules({ children }) {
  const { ssoUser } = useUser();
  const { selectedPlan, selectedRate } = usePlan();
  const { isSubscriber } = useSubscription();
  return (
    <Go to="/" when={!selectedPlan || !selectedRate || isSubscriber}>
      <Go
        to={process.env.ORG_REQUEST_LINK}
        when={selectedPlan?.key === 'organization'}
      >
        {/* if a plan, rate and sso user exists, assume the user wants to purchase */}
        <Go to="/paymentdetails" when={ssoUser}>
          {children}
        </Go>
      </Go>
    </Go>
  );
}
