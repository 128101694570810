import React from 'react';
import { Redirect } from '@reach/router';
import { isExternalURL } from './functions';

export default function CustomRedirect({ when, to, children }) {
  //* redirect when the condition is true
  if (when) {
    if (isExternalURL(to)) {
      return (window.location = to);
    } else {
      return <Redirect to={to} noThrow />;
    }
  }

  //* render children
  return <div>{children}</div>;
}
