import React from 'react';
import { Location } from '@reach/router';

import CreateAccountForm from '@components/Account/CreateAccountForm';
import PlanSummaryCard from '@components/PlanSummaryCard';

const Account = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-8">
          <Location>
            {({ location }) => <CreateAccountForm location={location} />}
          </Location>
        </div>
        <div className="col-12 order-first order-xl-last col-xl-4">
          <Location>
            {({ location }) => <PlanSummaryCard location={location} />}
          </Location>
        </div>
      </div>
    </>
  );
};

export default Account;
