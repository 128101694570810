import React, { useState, useRef } from 'react';
import { navigate } from 'gatsby';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import useAxios from 'axios-hooks';
import { useTranslation, Trans } from 'react-i18next';
import FormGroup from '@util/Forms/FormGroup';
import AlertError from '@util/Errors';

import AcceptTerms from '@components/Legal/AcceptTerms';
import LegalLink from '@components/Legal/LegalLink';

import isBrowser from '@util/isBrowser';
import { useUser } from '@util/providers/AuthProvider';
import URLS from '@config/urls';

import FormattedCountrySelect from '@util/Forms/FormattedCountrySelect';
import PasswordMeter from './CreateAccountPasswordMeter';
import EmailFoundModal from './Modal/EmailFound';
import { useEffect } from 'react';
import { useCallback } from 'react';

const APP_ROOT = isBrowser ? window.location.origin : '';

const userDefaults = {
  email: '',
  confirmEmail: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  country: '',
  passwordScore: 0,
  recaptcha: '',
  acceptTerms: false,
};

export default function CreateAccountForm({ location }) {
  const { t } = useTranslation();
  const [formError, setFormError] = useState();
  const [emailFoundModal, setEmailFoundModal] = useState(false);
  const recaptchaRef = useRef();
  const formikRef = useRef();
  const { signInSilent } = useUser();
  const [
    {
      // data: createAccountData,
      // loading: createAccountLoading,
      error: createAccountError,
      response: createAccountResponse,
    },
    createAccountPost,
  ] = useAxios(
    {
      url: URLS.CREATE_ACCOUNT,
      method: 'POST',
      withCredentials: true,
    },
    { manual: true }
  );

  const required = t('validation.required');
  const CreateAccountSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validation.invalidEmail'))
      .trim()
      .lowercase()
      .required(required),

    confirmEmail: Yup.string()
      .email('Invalid email')
      .trim()
      .lowercase()
      .oneOf([Yup.ref('email')], t('validation.emailsMustMatch'))
      .required(required),

    firstName: Yup.string()
      .trim()
      .required(required),
    lastName: Yup.string()
      .trim()
      .required(required),
    password: Yup.string()
      .min(8, ' ')
      .required(required),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('validation.passwordsMustMatch'))
      .required(required),
    country: Yup.string().required(required),
    passwordScore: Yup.number().min(1, 'score is no good'),
    acceptTerms: Yup.bool().oneOf([true], required),
    recaptchaValid: Yup.bool(),
  });

  const toggleEmailFoundModal = useCallback(() => {
    setEmailFoundModal(emailFoundModal => !emailFoundModal);
  }, []);

  useEffect(() => {
    if (createAccountResponse) {
      if (createAccountResponse.status === 201) {
        //* silent sign in with newly created SSO account
        signInSilent({ getBmoUser: false, geoLocate: false });
      }
    }
  }, [createAccountResponse, signInSilent]);

  useEffect(() => {
    //* check if the email submitted already exists as an SSO account

    if (createAccountError) {
      console.log({ createAccountError });
      formikRef.current.setSubmitting(false);
      if (
        createAccountError.response &&
        createAccountError.response.data.code === 6
      ) {
        toggleEmailFoundModal();
      } else {
        setFormError(createAccountError);
      }
    }
  }, [createAccountError, toggleEmailFoundModal]);

  return (
    <Formik
      ref={formikRef}
      enableReinitialize
      initialValues={userDefaults}
      validationSchema={CreateAccountSchema}
      onSubmit={(values, actions) => {
        if (values.recaptchaValid === true) {
          // const url = `${process.env.SSO_URL}/api/account/signin`;

          setFormError();

          const params = {
            Email: values.email.toLowerCase().trim(),
            ConfirmEmail: values.confirmEmail.toLowerCase().trim(),
            Password: values.password,
            ConfirmPassword: values.confirmPassword,
            'g-recaptcha-response': recaptchaRef.current.getValue(),
            clientId: 'bmo-subscription',
            returnUrl: `${APP_ROOT}/login/`,
            firstname: values.firstName,
            lastname: values.lastName,
            countrycode: values.country,
          };

          createAccountPost({
            data: params,
          });
        } else {
          recaptchaRef.current.execute();
          actions.setSubmitting(false);
        }
      }}
      render={({
        values,
        isValid,
        isSubmitting,
        setFieldValue,
        submitForm,
      }) => {
        function partialFormReset() {
          setFieldValue('email', '');
          setFieldValue('confirmEmail', '');
          recaptchaRef.current.reset();
          // setFieldValue('recaptcha', '');
        }

        function setRecaptchaTrue() {
          setFieldValue('recaptchaValid', true);
          submitForm();
        }

        return (
          <>
            <AlertError
              isOpen={!!formError}
              heading={t('validation.errorOccurred')}
            >
              {formError && formError.message}
            </AlertError>

            <Form>
              <div className="row">
                <FormGroup name="email" />
                <FormGroup name="confirmEmail" />
                <FormGroup name="firstName" />
                <FormGroup name="lastName" />

                <div className="col-md-6 form-group">
                  <Field hidden name="passwordScore" readOnly />
                  <label htmlFor="password">{t('forms.password')}</label>
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    id="passwordField"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="invalid-feedback d-block"
                  />
                  <PasswordMeter
                    password={values.password}
                    setPasswordScore={score =>
                      setFieldValue('passwordScore', score)
                    }
                  />
                </div>
                <FormGroup
                  name="confirmPassword"
                  fieldProps={{ type: 'password' }}
                />
                <FormattedCountrySelect fromSSO={true} />
              </div>

              <AcceptTerms>
                {/* prettier-ignore */}
                <Trans i18nKey="legal.tdTermsAndConditions">
                  I agree to the
                  <LegalLink
                    markdown={false}
                    url="https://download.mytobiidynavox.com/MyTobiiDynavox/TermsAndConditions.html"
                  >TD terms</LegalLink>
                </Trans>
              </AcceptTerms>

              <div className="row">
                <div className="col-12 form-group">
                  <ReCAPTCHA
                    sitekey={process.env.RECAPTCHA_SSO_KEY}
                    size="invisible"
                    ref={recaptchaRef}
                    onChange={setRecaptchaTrue}
                  />
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  id="CreateAccountSubmit"
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting && (
                    <>
                      <span
                        className="spinner-corner spinner-corner-sm align-middle mt-n1"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                    </>
                  )}
                  {isSubmitting
                    ? t('status.creatingAccount')
                    : t('action.next')}
                </button>
                <button
                  type="button"
                  onClick={() => navigate('/')}
                  id="CreateAccountCancel"
                  className="btn btn-outline-secondary"
                  disabled={isSubmitting}
                >
                  {t('action.cancel')}
                </button>
              </div>
            </Form>
            <EmailFoundModal
              isOpen={emailFoundModal}
              toggle={toggleEmailFoundModal}
              secondaryAction={partialFormReset}
            />
          </>
        );
      }}
    />
  );
}
